import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useQuery, useMutation } from 'react-query';
import { Loading } from '../../../Loading/Loading';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { modalError } from '../../../SweetAlert/Error';
import { modalSucces } from '../../../SweetAlert/Success';
import { formatter } from '../../../../common/utils/FormattPrice';
import Api from '../../../../common/Api/Api';
import { useEffect } from 'react';
const { confirm } = Modal;




export const CloseShells = ({ visible, setVisible, data }) => {

  // muestra modal con pregunta de confirmación

  function showConfirm() {
    confirm({
      title: '¿Esta seguro que desea cerrar esta venta?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      onOk() {
        mutation.mutate({ "idunique": data?.IdUnique })
      }
    });
  }

  // Activa las peticion de tipo post controlando la respuesta de la misma

  const mutation = useMutation(data => {
    return Api.post('/products/CloseSale', data)
  }, {
    onSuccess: data => {
      if (data?.ok === false) {
        modalError({ message: data?.payload.Message ? data?.payload.Message : 'Revisa tus datos, por favor' });
      } else {
        modalSucces({ message: "La petición se ha realizado de manera exitosa", reload: true, title: 'Cerrado' });
      }
    },
    onError: () => {
      modalError({ message: 'Parece que tenemos problemas' });
    }
  })


  // Petición get para traer los productos consumidos 

  const { data: dataClient, isLoading } = useQuery(["sellCloseData", data?.IdUnique], () =>
    Api.get("/products/GetProductConsumed/" + data?.IdUnique)
  );
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if(dataClient){
      let totalf = 0
      dataClient.payload.forEach(element => {
         totalf = element.total + totalf
      });
      setTotal(totalf)
    }
  }, [dataClient])
  return (

    <Modal 
        key="1" 
        className="flex flex-col " 
        title={<h1 style={{ color: 'white' }}>Cerrar venta del cliente</h1>}
        visible={visible} 
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }} onCancel={() => setVisible(!visible)}
    >

      <div>
        <Table className="tabla">
          <Thead>
            <Tr>
                <Th className="text-left">Descripción</Th>
                <Th className="text-right">Precio</Th>
                <Th className="text-center">Cantidad</Th>
                <Th className="text-right">Total</Th>
            </Tr>
          </Thead>
          <Tbody className="bg-blue-100 rounded-sm ">
            {Array.isArray(dataClient?.payload) && <>
              {dataClient?.payload.map(i =>
                <Tr>
                  <Td>
                    <div className="item text-left">{i?.NameProduct}</div>
                  </Td>
                  <Td>
                    <div className="Total">{formatter.format(i?.UnitValue)}</div>
                  </Td>
                  <Td>
                    <div className="item text-center">{i?.Amount}</div>
                  </Td>
                  <Td>
                    <div className="Total">{formatter.format(i?.total)}</div>
                  </Td>
                </Tr>
              )}
            </>}
            <Tr>
                <Th className="tTotal">Valor Total:</Th>
                <Th></Th>
                <Th></Th>
                <Th><div className="VTotal">
                {formatter.format(total)}
                </div></Th>
              </Tr>
          </Tbody>
        </Table>
        <Button onClick={showConfirm} className="btn-close mt-10" > Cerrar venta </Button>
      </div>
      <Loading visible={isLoading} />
    </Modal>

  )
}
