import React from "react";
import { put, takeLatest, all } from "redux-saga/effects";
import { reservations } from "./ReservationsActions";
import Api from "../../common/Api/Api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { modalSucces } from "../../components/SweetAlert/Success";
import { message } from "antd";
const MySwal = withReactContent(Swal);

const modalError = ({ message }) =>
  MySwal.fire({
    icon: "error",
    title: <p>Tenemos un error</p>,
    heightAuto: "false",
    didOpen: () => {
      MySwal.clickConfirm();
    },
  }).then(() => {
    return MySwal.fire(<p>{message}</p>);
  });

function* postReservation({ payload: { data } }) {
  try {

    let modalOptions = { message: "El escenario ha sido reservado con éxito"};

    if (data.State === "Redimir") {
      modalOptions.preventReload = true;
    }else{
      modalOptions.reload = true;
    }
   
    const response = yield Api.post("/reservationWeb/insertResevation", data);
    if (response.ok) {
      yield put(reservations.postReservationResponse(response.payload));
      modalSucces(modalOptions);
    } else {
      const err = new TypeError("ERROR_LOGIN");
      yield put(reservations.postReservationResponse(err));
      modalError({ message: response.payload.Message?response.payload.Message:'Revisa tus datos, por favor' });
    }
  } catch (error) {
    modalError({ message: "Parece que tenemos problemas" });
    yield put(reservations.postReservationResponse(error));
  }
}

function* cancelReservation({ payload: { data } }) {
  try {
    const response = yield Api.post("/reservationWeb/cancelacion", data);
   console.log(response.ok)
    // if (response.ok) {
      yield put(reservations.cancelReservationResponse(response.payload));
      modalSucces({ message: "La cancelación se ha realizado con éxito",reload:true,title:'Cancelado' });
    // } else {
    //   const err = new TypeError("ERROR_LOGIN");
    //   yield put(reservations.cancelReservationResponse(err));
    //   modalError({ message: response.payload.Message?response.payload.Message:'Revisa tus datos, por favor' });
    // }
  } catch (error) {
    modalError({ message: "Parece que tenemos problemas" });
    yield put(reservations.cancelReservationResponse(error));
  }
}



function* ActionWatcher() {
  yield takeLatest(reservations.postReservation, postReservation);
  yield takeLatest(reservations.cancelReservation, cancelReservation);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
