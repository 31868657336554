import React, { useState } from "react";
import Select from "react-select";
import { Modal} from "antd";
import InputComponent from "../../../Inputs/InputComponent3/InputComponent3";
import { useForm } from "react-hook-form";
import { modalError } from "../../../SweetAlert/Error";
import { modalSucces } from "../../../SweetAlert/Success";
import { useMutation } from "react-query";
import Api from "../../../../common/Api/Api";
import { Loading } from "../../../Loading/Loading";

export const AddSellClient = ({ visible, setVisible, products, dataConsumer }) => {
  const {
    handleSubmit,
    setValue, // Agregamos esta constante al formulario para modificar el valor del IdProduct
    formState: { errors },
    register,
  } = useForm();


  // Activa las peticion de tipo post para insertar productos consumidos controlando la respuesta de la misma

  const mutation = useMutation(data => {
    return Api.post('/products/InsertProductsConsumed', data)
  }, {
    onSuccess: data => {
      if (data?.ok === false) {
        modalError({ message: data?.payload.message ? data?.payload.message : 'Revisa tus datos, por favor' });
      } else {
        modalSucces({ message: "Producto agregado", reload: true });
      }
    },
    onError: () => {
      modalError({ message: 'Parece que tenemos problemas' });
    }
  })


//Se recibe el array de los productos y si tiene datos devuelve o le asigna el payload a optionsdata sino le pasa un array vacío para que no genere 
//error al renderizar el select
const optionsData = Array.isArray(products?.payload) ? products.payload : [];

//Se recorre el array usando el metodo map y devuelve el array con las propiedades transformadas para que las reconozca el Select
const selectOptions = optionsData.map((i) => ({
  label: i.NameProduct,
  value: i.IdProduct,
}));

//Creamos los estados para trabajar con el select que tiene propiedades especiales de input
const [selectedProduct, setSelectedProduct] = useState(null);

//En esta Arrow Function le pasamos la opcion seleccionada para que actualice el estado de selectedProduct y ademas para que nos envíe ese valor 
//seleccionado en el select y se lo pasemos al IdProduct
const handleProductChange = (selectedOption) => {
  setSelectedProduct(selectedOption);
  setValue("IdProduct", selectedOption.value);
};


  //envia los datos del formulario a la petición post

  const onSubmit = (data) => {
    mutation.mutate({ ...data, NameCustomer: dataConsumer?.NameCustomer, idunique: dataConsumer?.IdUnique })
  }


  return (
    <Modal
      title={<h1 style={{ color: 'white' }}>Agregar Producto</h1>}
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col ">
        <div className="flex flex-row justify-evenly w-full">
          
          
          {/* <div className="input-component3 mr-3 flex flex-col ">
            <p> Productos </p>
            <select {...register("IdProduct", { required: true })}
            >
              {Array.isArray(products?.payload) && products?.payload.map(i =>
                <option id={i.IdProduct} value={i.IdProduct}>{i.NameProduct}</option>
              )}
            </select>
          </div> */}
         
          <div 
           className="input-component3 mr-3 flex flex-col"
          >
         <p> Productos </p>
          <Select
            className='input-1' {...register("IdProduct", { required: true })}
            options={selectOptions}
            value={selectedProduct}
            onChange={handleProductChange}
          />
          </div>

          <div 
            className="input-component3 flex flex-col">
            
            <p>Cantidad</p>
            <input 
              className="minInput" 
              type="text" required pattern="[0-9]+" {...register("Amount", { required: true })}
            />
          </div>

        </div>

        <input 
          type="submit" 
          className="btn-close p-2" 
          value="Aceptar" 
          />

      </form>
      <Loading visible={mutation.isLoading} />
    </Modal>
  );
};
