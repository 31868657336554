import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent3/InputComponent3";
import { useForm } from "react-hook-form";
import File from "../../Inputs/Files.jsx";
import { UploadFirebase } from "../../firebase/PromiseUpload";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useDispatch, useSelector } from "react-redux";
import { local } from "../../../services/Locals/LocalActions";
import { Loading } from "../../Loading/Loading";
import InputHour from "../../Inputs/InputHour/InputHour.jsx";
import Map from "../../Map/Map.jsx";

export const RequestLocal = ({ visible, setVisible, data }) => {

  const userData = jwt_decode(Token.getToken());
  const [value, setvalue] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const [hand, setHand] = useState(false);
  const [openTime, setOpenTime] = useState('')
  const [closeTime, setCloseTime] = useState('')
  const [position, setPosition] = useState({ lat: "", lng: "" })
  const [promoRedem, setPromoRedem] = useState('');
  const dispatch = useDispatch();
  
  const [view, setView] = useState(false)
useEffect(()=>{
 if(value !== ''){
  setError(true)
 }
},[value])
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch
  } = useForm();

  useEffect(() => {
    if (hand === true)
      if (value == "") {
        setError(false);
      } else {
        setError(true);
      }
  }, [value, hand]);

  const handleError = () => {
    setHand(true);
  };

  // Valida los datos del formulario,sube los archivos a firebase,espera la promesa y activa la peticion post
useEffect(() => {
  if(loading === 'success'){
    window.location.reload()
  }
}, [loading])

  const onSubmit = async (e) => {
    console.log(e, 'dataaaaa')

    if (closeTime < openTime) {
      message.error("La hora de cierre no puede ser menor al inicial");
    } else if(closeTime == '00:00' || openTime == '00:00'){
      message.error("tiene que elegir una horario de apertura y de cierre ");
    }else if(position.lat === '' || position.lng === ''){
      message.error("tiene que elegir una ubicación ");
    }else{ 
      if (value === "") {
        setError(true);
      } else {
        
        if (
          value.type === "image/png" ||
          value.type === "image/jpg" ||
          value.type === "image/jpeg"
        ) {
          setLoading(true)
          return Promise.all([
            UploadFirebase({ value: value }).then(async (res) => {
              dispatch(
                local.postLocal({
                  ...e,
                  Longitude: `${position.lng}`,
                  Latitude: `${position.lat}`,
                  openTime: openTime,
                  closeTime: closeTime,
                  Country: data?.payload[0].Country,
                  Department: data?.payload[0].State,
                  Municipality: data?.payload[0].Municipality,
                  Logo: res,
                  idCompany: userData.data.IdCompany,
                  promoRedem: promoRedem,
                })
              )
              setLoading('success');
            }).catch(e => console.log(e)),
          ]);
        } else {
          message.error("El logo solo puede ser de tipo jpg,png o jpeg");
        }
      }
      setError(false);
    }
  };
 

  return (
    <Modal
      title={<h1 style={{ color: 'white' }}>Solicitud nuevo local</h1>}
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="request-local">
        <div className="request-local__content w-full flex">
          <div className="w-1/2 item itemModal pr-4">
          <div className='input-component3 flex flex-col'>
              <p>Nombre de usuario</p>
             <input type="text" required {...register("UserName", { required: true })}/>
          </div>         
          <div className='input-component3 flex flex-col'>
              <p>Telefono</p>
             <input type="number" required {...register("TelefonoLocal", { required: true })}/>
          </div>     
          <div className='input-component3 flex flex-col'>
              <p>Correo electronico</p>
             <input type="text" required {...register("Email", { required: true })}/>
          </div> 
          <div className='input-component3 flex flex-col'>
              <p>Nombre local</p>
             <input type="text" required {...register("NameLocal", { required: true })}/>
          </div> 
          <div className='input-component3 flex flex-col'>
              <p>NIT</p>
             <input type="number" required {...register("NitLocal", {
                required: true,
                pattern: /[0-9]{4}/,
                minLength: 7,
                maxLength: 11,
              })}/>
              {errors.NitLocal ? <span>El nit debe tener mínimo 7 dígitos máximo 11</span>: <></>}
          </div> 
          <div className="ubiContainer">    
            <div className="ubiBtn flex">
              <label className="ubic p-1" onClick={() => setView(true)}>Elegir ubicación</label>
                <Map position={position} setPosition={setPosition} view={view} setView={setView} />
            </div>
            <div className="flex ubications">
              <div className="ubiIn flex ">
                <p>Latitud:</p>
                <input className="minInput" type="number " readOnly value={position.lat} required />
              </div>
              <div className="ubiIn flex">
                <p>Longitud:</p>
                <input className="minInput" type="number" readOnly value={position.lng} required />
              </div>
            </div>
            </div>
          </div>
          <div className="w-1/2 item itemModal pl-3">
            
          <div className='input-component3 flex flex-col'>
              <p>Dirección</p>
             <input className="minInput" type="text" required {...register("LocalAddress", { required: true })}/>
          </div> 
          <div className='input-component3 flex flex-col'>
              <p>Canchas Redimibles</p>
             <input className="minInput" type="number" required {...register("promoRedem", { required: true })} onChange={(e) => setPromoRedem(e.target.value)}/>
          </div>     
            <div className="w-11/12 pb-6" >
              <p className="text-sm/[14] font-medium text-[#322b5f]">Hora de apertura</p>
              <div className="">
                <InputHour set={setOpenTime}/>
              </div>
            </div>
            <div className="w-11/12 pb-4">
              <p className="text-sm/[14] font-medium text-[#322b5f]">Hora de cierre</p>
              <div className="">
                <InputHour set={setCloseTime} />
              </div>
            </div>
            <div className="w-11/12">
              <p className="font-bold mt-5">Logo</p>
              <File
                typeData={["image/png", "image/jpg","image/jpeg"]}
                setvalue={setvalue}
                type='logo'
                value={value}
              />
              {error !== true ? (
                <span className="text-base text-red-400">
                  El logo debe estar en formato png o jpg y tener un tamaño  de 640x360
                </span>
              ) : <></>}
            </div>
          </div>
        </div>
        <button
          onClick={handleError}
          type="submit"
          className="submit"
        >Enviar</button>
      </form>
      <Loading visible={loading} />
    </Modal>
  );
};
