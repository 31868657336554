// import { useQuery } from "react-query";
// import Api from "../../../common/Api/Api";
// import React, { useState } from 'react';
// import { Button } from 'antd';

// const SendCode = () => {
//   const [confirmationCode, setConfirmationCode] = useState('');
//   const [message, setMessage] = useState('');

//   const { data = {}, isLoading, refetch } = useQuery(
//     "getCodigo",
//     () => Api.get('/reservationWeb/GetCodeHistory/3').then(response => response.data),
//     {
//       refetchOnWindowFocus: false,
//       enabled: true, // Enable the query to run immediately
//     }
//   );

//   const verifyCode = () => {
 
//     if (confirmationCode === data.codeRedeem) {
//       setMessage('Código de confirmación verificado correctamente.');
//     } else {
//       setMessage('Código de confirmación incorrecto.');
//     }
//   };

//   return (
//     <div className='pt-3 space-y-2'>
//       <h2>Código de confirmación</h2>
//       <input
//         type="text"
//         className='flex-1 border border-gray-300 rounded-md p-2'
//         placeholder="Ingrese el código"
//         value={confirmationCode}
//         onChange={(e) => setConfirmationCode(e.target.value)}
//       />
//       <Button className='Primary' onClick={verifyCode} disabled={isLoading}>
//         {isLoading ? 'Verificando...' : 'Verificar Código'}
//       </Button>
//       {message && <p>{message}</p>}
//     </div>
//   );
// };

// export default SendCode;
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Api from "../../../common/Api/Api";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useForm } from "react-hook-form";
import { modalError } from "../../SweetAlert/Error";
import { modalSucces } from "../../SweetAlert/Success";
import { Loading } from "../../Loading/Loading";
import NoData from "../../../assets/img/NoData.svg";

const fetchApiData = async (IdUser) => {
  const response = await Api.get(`/reservationWeb/GetCodeHistory/${IdUser}`);	
  console.log("response gethistory", response);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.payload;
};

const updateApiData = async (data) => {
  const url = '/reservationWeb/UpdateHistoryReservation';
  const header = null; // Puedes personalizar los headers si es necesario

    const result = await Api.post(url, data, header);
    
    // Convierte la respuesta a un string JSON para inspección
    const jsonResponse = JSON.stringify(result);
   
    if (jsonResponse =='{"payload":{}}') {
      console.log('Error en la actualización:', jsonResponse);
    }
    if(jsonResponse=='{}'){
      modalSucces({ message: "Código confirmado: Sus puntos fueron redimidos exitosamente", reload: true });
  
    }

};

const SendCode = ({ dataUser }) => {
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState(false);
  const { handleSubmit, register, getValues } = useForm();
  const [isButtonVisible, setButtonVisible] = useState(true);
  const userData = jwt_decode(Token.getToken());
  const Local = userData.data.IdLocal;
  const IdUser = dataUser.payload[0].IdUser;
  const { data, error, isLoading, refetch } = useQuery('fetchData', ()=> fetchApiData(IdUser), { enabled });
  

  const mutation = useMutation(updateApiData, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetchData');
      // modalSucces({ message: "Información Actualizada", preventReload: true });
    },
    onError: (error) => {
      modalError({ message: `Actualización fallida: ${error.message}` });
    }
  });

  const handleFetchData = () => {
    console.log("usuario", IdUser);
    setEnabled(true);
    setButtonVisible(false);
    refetch();
  };

  const handleUpdateClick = () => {
    if (data) {
      console.log("data", data);
      console.log("IDUSER", IdUser);
      const values = getValues();
      if(data.codeRedeem == getValues('code')){
        console.log("valor del codigo: ",getValues('code'));
        mutation.mutate({...data, IdUser, Local});
      }else{
        modalError({ message: "El Código es incorrecto" });
      }
      
    }
  };

  return (
    <>
      <form className="flex flex-col mx-4 justify-center items-center w-full" onSubmit={handleSubmit(handleUpdateClick)}>
      {isButtonVisible &&(
        <button
          type="button"
          onClick={handleFetchData}
          className="w-10/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 px-2 rounded min-w-max"
        >
          Enviar Código
        </button>
      )}
        {isLoading && <Loading visible={true} />}
        {error && <p>Error: {error.message}</p>}
        {data && (
          <div>
            {/* <h3>Data:</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre> */}
            <div className="input-component3 w-64">
              {/* <p>Code</p> */}
              <input
                {...register("code")}
                name="code"
                type="text"
                placeholder="Ingrese código"
              />
            </div>
            <button
              type="submit"
              className="w-10/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 rounded min-w-max"
            >
              Confirmar Código
            </button>
          </div>
        )}
        {!data && !isLoading && (
          <img className="mx-auto my-12 w-2/6" alt="no-data" src={NoData} />
        )}
      </form>
    </>
  );
};

export default SendCode;