import React from 'react'
import { Modal } from 'antd'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useQuery } from "react-query";
import { Loading } from '../../../Loading/Loading';
import Api from '../../../../common/Api/Api';
import jwt_decode from "jwt-decode";
import { Token } from "../../../../common/Storage/Token";
import { formatter } from '../../../../common/utils/FormattPrice';


export const HistoryDeatail = ({ visible, setVisible, dataHistory }) => {
  const userData = jwt_decode(Token.getToken());


  // Petición get para traer los datos del historial de ventas  

  const { data, isLoading } = useQuery(["HistoryDeatail", dataHistory], () =>
    Api.post("/products/DetailHistorySale", {
      idLocal: userData?.data?.IdLocal,
      dataSale: dataHistory?.DateRegisterProductsConsumed
    })
  );


  return (
    <>
      <Modal 
          // className="flex flex-col text-center text-2xl"  
          title={<h1 style={{ color: 'white' }}>Venta</h1>}
          visible={visible} 
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }} 
          onCancel={() => setVisible(!visible)}
      >
        <div >
          <Table>
            <Thead>
              <Tr>
                <Th>Cliente</Th>
                <Th className="text-right">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(data?.payload) && <>
                {data?.payload.map(i =>
                  <Tr>
                    <Td>
                      <div className="item text-left">{i?.NameCustomer}</div>
                    </Td>
                    <Td>
                      <div className="item ml-1 text-right">{formatter.format(i?.total)}</div>
                    </Td>
                  </Tr>
                )}
              </>}
            </Tbody>
          </Table>
          <div className="flex mt-5  w-full py-4 mx-auto justify-center ">
            <h2 className="VTotal">Total: </h2>
            <span className='tTotal'>{formatter.format(dataHistory?.total)}</span>
          </div>
        </div>
      </Modal>
      <Loading visible={isLoading} />

    </>
  )
}
