import React, { useState } from "react";
import { Modal } from "antd";
import { reservations } from "../../../services/Reservations/ReservationsActions";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import Api from "../../../common/Api/Api";
import { Loading } from "../../Loading/Loading";
import NoData from "../../../assets/img/NoData.svg";
import { BiUserCircle } from "react-icons/bi";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useForm } from "react-hook-form";
import { UserOutlined } from "@ant-design/icons";
import SendCode from "../Owner/SendCode";
import { modalError } from "../../SweetAlert/Error";
import { modalSucces } from "../../SweetAlert/Success";

export const AddReservation = ({ dataDetail, visible, setVisible }) => {
  const userData = jwt_decode(Token.getToken());
  const { loading } = useSelector((state) => state.reservation);
  const [document, setDocument] = useState("");
  const [isButtonVisible, setButtonVisible] = useState(true);
  // const [documents, setDocuments] = useState();
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState(false);
  //Obtiene los puntos del usuario le envío documento, tipo Cancha, id local
  //Devuelve el idUser, NameUser, Points
  const { data, isLoading, refetch } = useQuery(
    "getUserIdentification",
    () =>
      Api.get(
        `/reservationWeb/GetUserPoints/${document}/${dataDetail.params.id}/${userData.data.IdLocal}`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { handleSubmit, register, getValues } = useForm();

  // Esta función se llama al hacer clic en el botón
  const handleFetchDocument = () => {
    console.log("datos de data:", data);
    refetch();
  };

  // Envia los datos del formulario al dispatch de redux
  const postReservation = (values) => {
    console.warn(document);
    dispatch(
      reservations.postReservation({
        ReservationDate: dataDetail?.date,
        ReservationHour: dataDetail?.e.hour,
        IdField: parseInt(dataDetail.params.id),
        Iduser: parseInt(data.payload[0].IdUser),
        Name: values.Name,
        Phone: data.payload[0].Phone,
        State: values.State,
      })
    );
  };

  const onSubmit = (values) => {
    // postReservation(values);
  };

  //Codigo de acción de la modal

  const handleCancel = () => {
    setVisible(!visible);
    window.location.reload();
  };
  const handleClickRedem = () => {
    setButtonVisible(false);
    if (data.payload[0].promoRedem == 0) {
      modalError({ message: "Este local no tiene promociones" });
    } else {
      if (data.payload[0].Points >= data.payload[0].promoRedem) {
        setClicked(true);
        const values = getValues();
        values.State = "Redimir";
        postReservation(values);

        // modalSucces({ message: "Reservación redimida correctamente", preventReload: true });
      } else {
        modalError({
          message:
            "El usuario no tiene puntos suficientes para redimir reserva",
        });
      }
    }
  };

  return (
    <Modal
      title={<h1 style={{ color: "white" }}>Agregar reservación</h1>}
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={handleCancel}
      //  onCancel={() => setVisible(!visible)}
    >
      <form className="flex flex-col mx-4 justify-center items-center w-full">
        <div className="input-component3 w-64">
          <p>Número de documento</p>
          <input
            pattern="[0-9]+"
            type="number"
            onChange={(e) => setDocument(e.target.value)}
            placeholder="1117..."
          />
        </div>
        <button
          type="button"
          onClick={handleFetchDocument}
          className="flex text-center mx-auto cursor-pointer w-5/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 px-2.5 hover:bg-gray-200"
        >
          Buscar Documento
        </button>
      </form>
      {!data?.payload ? (
        <img className="mx-auto my-12 w-2/6" alt="no-data" src={NoData} />
      ) : data.payload.message ? (
        //  console.log(data.payload.message),
        <div className="mx-auto">
          <img className="mx-auto my-12 w-2/6" alt="no-data" src={NoData} />
          <p className="font-medium mx-auto text-3xl text-blue-900 text-center">
            {data.payload.message}
          </p>
        </div>
      ) : data.payload[0].NameUser === "Onplay" ? (
        <div className="flex flex-col space-between items-center my-6">
          <div className="w-7/12 flex self-start space-between items-center">
            <BiUserCircle className="font-medium mx-auto text-2xl text-blue-900 text-center" />
            <p className="font-medium mx-auto text-2xl text-blue-900 text-center mr-6">
              {data.payload[0].NameUser}
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-component3 w-64">
              <p>Nombre</p>
              <input
                {...register("Name")}
                name="Name"
                type="text"
                placeholder="opcional"
              />
            </div>
            <div className="input-component3 w-64 mb-4">
              <p>Número de contacto</p>
              <input
                {...register("Phone")}
                name="Phone"
                type="number"
                placeholder="opcional"
                pattern="[0-9]+"
              />
            </div>
            <input
              type="submit"
              className="flex text-center mx-auto cursor-pointer w-5/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 px-2.5 hover:bg-gray-200"
              value="Agendar"
            />
          </form>
        </div>
      ) : (
        <div className="flex space-between items-center my-6">
          <div className="w-7/12 flex-col space-between items-center">
            <UserOutlined
              style={{ color: "#043664" }}
              className="font-medium mx-auto text-5xl text-blue-900 text-center"
            />
            <p className="font-medium mx-auto text-2xl text-blue-900 text-center mr-6">
              {data.payload[0].NameUser}
            </p>
            <p className="font-medium mx-auto text-l text-blue-900 text-center mr-6">
              Puntos:
              {data.payload[0].Points}
            </p>
          </div>
          <ul>
            {isButtonVisible && (
              <li>
                <button
                  onClick={() => {
                    const values = getValues();
                    postReservation(values);
                  }}
                  className="w-10/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 px-2 rounded min-w-max"
                >
                  Reservar
                </button>
              </li>
            )}
            <br></br>
            {isButtonVisible && (
              <li>
                <button
                  onClick={handleClickRedem}
                  className="w-10/12 rounded-lg bg-transparent border-2 border-blue-900 text-blue-900 py-1 px-2.5 rounded min-w-max"
                >
                  Redimir
                </button>
              </li>
            )}
            <li>{clicked ? <SendCode dataUser={data} /> : null}</li>
          </ul>
        </div>
      )}
      <Loading visible={isLoading || loading} />
    </Modal>
  );
};
