import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Layout, Popover, Modal } from "antd";
import { auth } from "../../../services/Auth/AuthActions";
import { MdVerified } from 'react-icons/md';
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useQuery } from 'react-query';
import Api from '../../../common/Api/Api.js';
import { apiUrl } from '../../../common/Config/Environments';
import Redem from '../../Modals/Owner/SelectRedem.js';
import SelectRedem from '../../Modals/Owner/SelectRedem.js';

const { Header } = Layout;

// Componente de cabecera del dashboard

export const HeaderC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);//manejador de estados de visibilidad de la Modal
    const userData = jwt_decode(Token.getToken());
    const dispatch = useDispatch();

    // Contenido de la etiqueta Popover
    const content = (
        <div className="cursor-pointer">
            <p onClick={() => dispatch(auth.logout())}>Cerrar sesión</p>
        </div>
    );

    const { data: dataLocal, isLoading } = useQuery(
        'locals',
        () =>
            fetch(
                `${apiUrl}/Local/getLocal/` + userData.data.IdCompany,
                {
                    method: 'GET',
                }
            )
                .then(async (response) => {
                    return await response.json();
                })
                .catch((err) => {
                    console.log(err);
                }),
        {
            refetchOnWindowFocus: false,
            enabled: true,
        }
    );

    const validateUser = (data) => {
        if (data.IdRole == 1) {
            return 'Administrador';
        } else if (data.IdRole == 2) {
            return data.NameCompany;
        } else {
            let name = '';
            if (Array.isArray(dataLocal)) {
                dataLocal.forEach(element => {
                    if (element.IdLocal == data.IdLocal) {
                        name = element.NameLocal;
                    }
                });
            }
            return name;
        }
    };

//Controles de la Modal de Redimir puntos
    const showModal = () => {
		if(userData.data.IdRole == 3){
        	setIsModalVisible(true);
		}else{
			alert(`Solo el administrador del local puede redimir puntos`);
		}
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
//Fin controles
    return (
        <Header className="header">
            <div className="header_title">
                <span>Bienvenido,  <b>{validateUser(userData?.data)}</b> </span>
            </div>
            <div className="header_options">
                <div className="notification">
                    <MdVerified onClick={showModal} />
                    <div className="dot"></div>
                </div>
                <Popover className="cursor-pointer" placement="top" content={content} trigger="click">
                    <img alt="profile" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                </Popover>
            </div>

			<Modal //Modal para Redimir Puntos
				title={<h1 style={{ color: 'white' }}>Consultar Puntos</h1>}
				visible={isModalVisible} 
				onCancel={handleCancel}>
				<SelectRedem />
            </Modal>
           
        </Header>
    );
};