import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import './styles.scss'
import Swal from 'sweetalert2';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment);



const CalendarComponent = ({ type, hours, setdate, data, Day, handleReservetion, handleCancelation}) => {
const [view, setView] = useState('month')
const [dayCalendar, setDayCalendar] = useState(Day)
const [events, setEvents] = useState([])

useEffect(() => {
 console.log('horas:', hours);
 console.log(handleReservetion, handleCancelation);
}, [hours])

// Función para obtener las propiedades de estilo personalizadas para los días vacíos
const getEmptyDayStyle = (date) => {
    // Define los estilos para los días vacíos
    const today = moment().startOf('day'); // Obtén el día actual sin la hora
    const isToday = moment(date).isSame(today, 'day'); // Comprueba si la fecha es igual al día actual

    if (isToday) {
      return {
        style: {
          cursor: 'pointer',
          backgroundColor: '#f1f1f1'
        }
      };
    }

    return {
    style: {
      cursor: 'pointer'
    }
    };
  };
    const CustomTimeColumnHeader = () => (
      <div className='calendarDayHeader'>
        <div className='calendarDayHeader_hor'>Horarios</div>
      </div>
    );
    const CustomHeader = () =>{
      return(
       <div className="calendarReserv">Reservas</div>
      )
    }
    
    const components = view == 'day' ? {
      timeGutterHeader: CustomTimeColumnHeader,
      dateCellWrapper: CustomHeader
    } : {timeGutterHeader: CustomTimeColumnHeader};


  const minTime = new Date();
  const maxTime = new Date();
  const hourclose = 23
  minTime.setHours(Number(hours[0].substring(0,2)), 0,0); 
  maxTime.setHours(Number(hours[1].substring(0,2))+ (Number(hours[1].substring(0,2)) === 23 ? 0:1 ) , Number(hours[1].substring(0,2)) === 23 ? 59 : 0,0); 
  //estos valores generan la fecha actual 
  const date = new Date();
  const currentHour = date.getHours()
  const currentMonth = date.getMonth();
  const getMounth = (e) => {
    const mounth = e.split('-')[1].substring(0,1) == '0' ? Number(e.split('-')[1].substring(1,2))-1 : Number(e.split('-')[1].substring(0,2))-1
    return mounth
  }
  const day = date.getDate()
  //función para habilitar horas
const handleSelect = (e) =>{
  console.log(e);
  if(e.slots.length !== 2){
  }else{
      //estos valores generan la fecha elegida
      const startHour = e.start.getHours()
      const currentDay = e.start.getDate()
      const month = e.start.getMonth();
      const year = e.start.getFullYear()
      let flag = false
      // para posteriormente validar si la fecha seleccionada está disponible
      events.forEach((event)=>{
        if(startHour === event.start.getHours()){
          flag = true
        }
      })
      if(!flag){
        console.log('startHour', startHour);
        console.log('currentHour', currentHour);

        if (currentDay == day && month == currentMonth) {
          if (startHour >= currentHour && startHour !== 0 ) {
            console.log('jajdikdj', startHour);
            handleReservetion({ hour: `${startHour}:00` })
          }
        } else if (currentDay > day && month >= currentMonth && startHour !== 0) {
          console.log('jajdikd222', startHour);

          handleReservetion({ hour: `${startHour}:00` })
        }
      }
  }
}

  const handleSubstring = (h) => {
    if (typeof h == 'string') {
      const hour = h.split(':')

      return hour[0]
    }
  }
const handleCancel = (event) =>{

if(event.start >= date && view == 'day'){
  Swal.fire({
    title:'¿Desea eliminar esta reserva',
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    confirmButtonColor: '#002370'
  }).then((e )=> {if(e.isConfirmed){
    handleCancelation({ hour: { hour: `${event.start.getHours()}:00` }, id: event.id })
  }})
}
}

  useEffect(() => {
    let array = []
    if (data?.payload) {
      if (Array.isArray(data.payload)) {
          data.payload.forEach(item => {
            array.push(
              {
                id: item.IdUser,
                title: item.NameUser,
                description: 'Reserva',
                start: new Date(date.getFullYear(), getMounth(dayCalendar), dayCalendar.split('-')[2], Number(handleSubstring(item.ReservationTime)), 0),
                end: new Date(date.getFullYear(), getMounth(dayCalendar), dayCalendar.split('-')[2], Number(handleSubstring(item.ReservationTime)) + 1, 0)
              }
            )
          })
          if(array[0]){
              setEvents([...array])
            }
          }
      }
    
  }, [data])
  const messages = {
    today: 'Hoy',
    next: 'Siguiente',
    previous: 'Anterior',
    day: 'Día',
    month: 'Mes'
  };
  const updateDate = (e) => {
      setdate(`${e.getFullYear()}-${Number(e.getMonth()) + 1 > 9 ? Number(e.getMonth()) + 1 : '0' + (Number(e.getMonth()) + 1)}-${e.getDate().length == 1 ? '0'+e.getDate() : e.getDate()}`)
      setDayCalendar(`${e.getFullYear()}-${Number(e.getMonth()) + 1 > 9 ? Number(e.getMonth()) + 1 : '0' + (Number(e.getMonth()) + 1)}-${e.getDate().length == 1 ? '0'+e.getDate() : e.getDate()}`)
  }
  const eventStyleGetter = (event, start, end, isSelected) => {
    // Define tu lógica para asignar estilos a los eventos
    const backgroundColor = '#1f65ac'
    const color = 'white';

    return {
      style: {
        backgroundColor,
        color,
      },
    };
  };
  return (
    <div className={`${'calendar'}`}>
      <Calendar
        messages={messages}
        min={minTime}
        max={maxTime}
        localizer={localizer}
        defaultDate={dayCalendar}
        defaultView={'month'}
        views={['day', 'month']}
        events={events}
        onView={(e)=> setView(e) }
        dayPropGetter={getEmptyDayStyle}
        components={components}
        culture="es"
        formats={{
          timeGutterFormat: 'h:mm A',
          eventTimeRangeFormat: ({ start, end }) =>
            `${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`,
          dayHeaderFormat: 'dddd DD/MM',
        }}
        selectable
        style={{ height: '75vh' }}
        onSelectEvent={event => handleCancelation ? handleCancel(event) : ''}
        onSelectSlot={handleReservetion ? handleSelect : ''}
        onNavigate={e => updateDate(e)}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default CalendarComponent;
