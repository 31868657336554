import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import InputComponent from '../../Inputs/InputComponent3/InputComponent3'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { modalError } from '../../SweetAlert/Error'
import { modalSucces } from '../../SweetAlert/Success'
import Api from '../../../common/Api/Api'
import { Loading } from '../../Loading/Loading'

export const EditLocalAdomin = ({ visible, setVisible, local }) => {
  const [correo, setCorreo] = useState('')

  useEffect(() => {
    setCorreo(local?.email)
  }, [local])

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm()

  // Activa las peticion de tipo post para actualizar la cancha  controlando la respuesta de la misma

  const mutation = useMutation(
    (data) => {
      return Api.post('/login/UpdatePasswordLocal', data)
    },
    {
      onSuccess: (data) => {
        if (data?.ok === false) {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : 'Revisa tus datos, por favor',
          })
        } else {
          modalSucces({
            message: 'El usuario se ha modificado de manera correcta',
            reload: true,
          })
        }
      },
      onError: () => {
        modalError({ message: 'Parece que tenemos problemas' })
      },
    }
  )

  //Valida que las dos contraseñas se han iguales,de ser así activa la peticion de lo contrario muestra un mensaje de error

  const onSubmit = (e) => {
    if (e.newpass !== e.newpass2) {
      message.error(' Las contraseñas no coinciden')
    } else {
    }
    mutation.mutate({
      idLocal: local?.IdLocal,
      newpass: e.newpass,
      email: e.email,
    })
  }

  return (
    <Modal
      className="z-1"
      title={<h1 style={{ color: 'white' }}>Editar datos de usuario</h1>}
      open={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mx-4 justify-center items-center w-full request-local "
      >
        <div className="request-local__content w-full flex ">
          <div className="w-full">
            <div className='input-component3 flex flex-col g-10'>
              <p>Correo electronico</p>
              <input  ut type="text" required onInput={(e) => setCorreo(e.target.value)} value={correo} {...register('email')} />
            </div>
            <div className='input-component3 flex flex-col g-10'>
              <p>Nueva contraseña</p>
              <input type="text" required {...register('newpass', { required: true })} />
            </div>
            <div className='input-component3 flex flex-col g-10'>
              <p>Repetir contraseña</p>
              <input type="text" required {...register('newpass2', { required: true })} />
            </div>
          </div>
        </div>
        <input type="submit" className="btn-close p-2" value="Aceptar" />
      </form>
      <Loading visible={mutation.isLoading} />
    </Modal>
  )
}
