import React from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent3/InputComponent3";
import { reservations } from '../../../services/Reservations/ReservationsActions'
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

export const CancelReservation = ({ visible, setVisible, detailData }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();


  // envia los datos del formulario a una action de redux para cancelar una reservación

  const onSubmit = (e) => {
    dispatch(reservations.cancelReservation({
      Titel: "Cancelación de Reserva",
      TypeNotification: e.TypeNotification,
      Description: e.Description,
      IdField: parseInt(detailData.params.id),
      Iduser: detailData.data.id,
      date: detailData.date,
      hour: detailData.data.hour.hour
    }))
  }


  return (
    <Modal
      title={<h1 style={{color:'white'}}>Cancelación de Reserva</h1>}
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col request-modal ">

        <div className="input-component3 mr-3 mb-5 flex flex-col">
          <p> Motivo de cancelación  </p>
          <select
          className="selectCancel"
          {...register("TypeNotification", { required: true })}>
            <option id="1" value="Decreto publico">Decreto público</option>
            <option id="2" value="Fallo electrico">Fallo eléctrico</option>
            <option id="3" value="Orden público">Orden público</option>
            <option id="4" value="Incumplimiento">Incumplimiento del usuario</option>
            <option id="5" value="Otro">Otro</option>
          </select>
        </div>
        <div className="input-component3 mr-3 mb-5 flex flex-col">    
        <p>Descripción</p>
        <input type="text" required  {...register("Description")}/>
        </div>
        <input type="submit" className="btn-close p-2" value="Aceptar" />
      </form>
    </Modal>
  );
};
