import React, { useEffect } from 'react'
import { GoogleMap, Marker, useJsApiLoader  } from '@react-google-maps/api';
import { Modal } from 'antd';
import './MapStyles.scss'
const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 1.6150,
  lng: -75.605
};

function MyComponent({position, setPosition, view, setView}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBN26d7Op9IlsQNJeaZICkqFi03R9jNT_Q",

  })

  const [map, setMap] = React.useState(null)
  const [zoom, setZoom] = React.useState(10)
  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(position.lat !== '' ? position : center);
    map.setZoom(zoom)
    map.setCenter(position.lat !== '' ? position : center)
    map.setOptions({
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
      ],
    });
    map.setMapTypeId('satellite');
    setMap(map)
  }, [zoom])
  useEffect(() => {
    setTimeout(() => {
        setZoom(17)
    }, 300);
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const handleClick = (e) =>{
    setPosition({lat: e.latLng.lat(), lng: e.latLng.lng()})
  }
  return isLoaded ? (
    <Modal
    title={<h1 style={{ color: 'white' }}>Elegir ubicación del local</h1>}
    open={view}
    okButtonProps={{ hidden: true }}
    cancelButtonProps={{ hidden: true }}
    onCancel={()=>setView(false)}
    bodyStyle={{width: '80vw', height:'70vh'}}

    >
      <GoogleMap
        onClick={(e)=> handleClick(e)}
        mapContainerStyle={containerStyle}
        center={position}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerClassName='mapCont'

      >
        <Marker position={{lat: position.lat, lng: position.lng }}/>
        <></>
      </GoogleMap>
    </Modal>
  ) : <></>
}

export default MyComponent