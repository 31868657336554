import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import InputComponent from '../../Inputs/InputComponent3/InputComponent3';
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { modalError } from '../../SweetAlert/Error';
import { modalSucces } from '../../SweetAlert/Success';
import File from "../../Inputs/Files.jsx";
import Api from '../../../common/Api/Api';
import { Loading } from '../../Loading/Loading';
import { UploadFirebase } from '../../firebase/PromiseUpload';

export const EditField = ({ visible, setVisible, field }) => {
  const [value, setvalue] = useState("");
  const [priceDay, setPriceDay] = useState('')
  const [priceNight, setPriceNight] = useState('')
  useEffect(() => {
   setPriceDay(field?.PriceDay)
   setPriceNight(field?.PriceNight)
   
  }, [field])
  
  const { data, isLoading } = useQuery("fieldTypes", () =>
    Api.get("/field/types")
  );
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  // Activa las peticion de tipo post para actualizar la cancha  controlando la respuesta de la misma


  const mutation = useMutation(data => {
    return Api.post('/field/UpdateField', data)
  }, {
    onSuccess: data => {
      if (data?.ok === false) {
        modalError({ message: data?.payload.message ? data?.payload.message : 'Revisa tus datos, por favor' });
      } else {
        modalSucces({ message: "El escenario se ha editado de manera correcta", reload: true });
      }
    },
    onError: () => {
      modalError({ message: 'Parece que tenemos problemas' });
    }
  })

  // Valida los datos del formulario,sube los archivos a firebase,espera la promesa y activa la peticion post


  const onSubmit = (e) => {
    return Promise.all([
      UploadFirebase({ value: value }).then(async (res) => {
        mutation.mutate({
          idField: field?.IdField,
          namefield: field.NameField,
          priceDay: priceDay,
          priceNight: priceNight,
          idFieldType: e.idFieldType,
        })
      }),
    ])
  };


  return (
    <Modal className="z-1" 
          title={<h1 style={{ color: 'white' }}>Editar cancha</h1>}
          visible={visible} 
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }} onCancel={() => setVisible(!visible)}>
      <form onSubmit={handleSubmit(onSubmit)} className="request-local">
        <div className='input-component3 flex flex-col gap-5'>
          <p>Precio en el día</p>
          <input type="number" className="minInput" required value={priceDay} onInput={(e)=> setPriceDay(e.target.value)}/>
        </div>
        <div className='input-component3 flex flex-col gap-5'>
          <p>Precio en el noche</p>
          <input type="number" className="minInput" required value={priceNight} onInput={(e)=> setPriceNight(e.target.value)}/>
        </div>
        <div className="input-component3 flex flex-col w-11/12">
          <p>Deporte </p>
          <select
          className='selectDep'
            name="idFieldType"
            {...register("idFieldType")}
          >
            {data?.payload?.map((i) => (
              <option value={i.IdFieldType}>{i.NameFieldType}</option>
            ))}
          </select>
        </div>
        <input type="submit" className="submit" value="Enviar" />
      </form>
      <Loading visible={mutation.isLoading || isLoading} />
    </Modal>
  )
}
