import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Pagination, Button } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { modalError } from "../../SweetAlert/Error";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import Api from "../../../common/Api/Api";

const SelectRedem = () => {
  const params = useParams();
  const userData = jwt_decode(Token.getToken());

  const [selectedSport, setSelectedSport] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 7; // Número de filas por página

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    getValues,
  } = useForm();

  // Consulta los deportes que tiene disponibles este local para mostrarlos en el SelectInput
  const { data: fieldsData } = useQuery(
    "fieldsData",
    () => Api.get("/local/getSportLocal/" + userData.data.IdLocal),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity, // Nunca marca los datos como obsoletos
      cacheTime: Infinity, // Mantiene los datos en caché indefinidamente
    }
  );
  const optionsData = Array.isArray(fieldsData?.payload)
    ? fieldsData.payload
    : [];

  const selectOptions = optionsData.map((i) => ({
    label: i.NameFieldType,
    value: i.IdFieldType,
  }));

  const handleSportChange = (selectedOption) => {
    setSelectedSport(selectedOption);
    setValue("IdFieldType", selectedOption.value);
  };

  // Obtenemos datos de redención
  const { data: redemData, refetch } = useQuery(
    "redemData",
    () =>
      Api.get(
        "/local/getRedemLocal/" +
          getValues("Identification") +
          "/" +
          selectedSport.value +
          "/" +
          userData.data.IdLocal
      ),
    {
      enabled: false, // La consulta no se ejecuta automáticamente
      onSuccess: (data) => {
        if (data && data.payload && data.payload.length > 0) {
          setTableData(data.payload);
          setCurrentPage(1); // Resetear la página actual al consultar
        } else {
          modalError({
            message:
              "No se encontraron reservas para este usuario en " +
              selectedSport.label,
          });
          setTableData([]); // Limpiar la tabla si no hay datos
        }
      },
      onError: (error) => {
        console.error("Error fetching redemption data:", error);
        alert("Error al obtener los datos de redención.");
      },
    }
  );

  const onSubmit = () => {
    if (!selectedSport) {
      modalError({ message: "Por favor, seleccione un deporte." });
      // alert("Seleccione un deporte.");
      return;
    }

    refetch(); // Ejecutar la consulta manualmente
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        if (prevSelectedRows.length < 10) {
          return [...prevSelectedRows, id];
        } else {
          return prevSelectedRows;
        }
      }
    });
  };

  // const redeemSelected = () => {
  //   setTableData((prevTableData) =>
  //     prevTableData.map((row) =>
  //       selectedRows.includes(row.id) ? { ...row, status: "Redimido" } : row
  //     )
  //   );
  //   setSelectedRows([]);
  //   alert(`Redimiendo puntos para los IDs: ${selectedRows.join(", ")}`);
  // };

  const totalPages = Math.ceil(tableData.length / pageSize);
  const currentData = tableData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {/* Incio del Formulario */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mx-4 px-4 justify-center w-full"
      >
        <div className="input-component3 flex flex-col">
          <p>Documento del Cliente</p>
          <input
            className="minInput"
            type="number"
            required
            {...register("Identification", { required: true })}
          />
        </div>
        <div className="input-component3 flex flex-col">
          <p>Seleccione el Deporte</p>
          <Select
            className="input-1"
            {...register("IdFieldType")}
            options={selectOptions}
            value={selectedSport}
            onChange={handleSportChange}
            placeholder="Seleccione un deporte"
          />
        </div>
        <input type="submit" className="btn-close p-2" value="Consultar" />
      </form>
      {/* final del Formulario */}

      {/* Tabla de la consulta */}
      {tableData.length > 0 && (
        <div className="modal">
          <br></br>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                {/* <th></th> */}
                <th>Nombre</th>
                <th>Deporte</th>
                <th>Fecha de Reserva</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, index) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  {/* <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                      disabled={
                        row.status === "Redimido" ||
                        (index >= 10 && !selectedRows.includes(row.id))
                      }
                    />
                  </td> */}

                  <td>{row.name}</td>
                  <td>{row.sport}</td>
                  <td>{row.date}</td>
                  <td>
                    {row.status === 0 ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br></br>
          <div className="pagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={tableData.length}
              onChange={handlePageChange}
            />
          </div>
          <br></br>
          {/* {selectedRows.length > 0 && selectedRows.length <= 10 && (
            <Button className="btn-close p-2" onClick={redeemSelected}>
              Redimir
            </Button>
          )} */}
        </div>
      )}
    </>
  );
};

export default SelectRedem;
