import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useQuery } from "react-query";
import { Loading } from '../../../Loading/Loading';
import Api from '../../../../common/Api/Api';
import { useState } from 'react';
import { formatter } from '../../../../common/utils/FormattPrice';
import './SellsClient.css'
import colors from 'webpack-dev-server/lib/utils/colors';

export const SellClient = ({ visible, setVisible, dataClient }) => {
  
  // Petición fetch para  las ventas por cliente 
  
  const { data, isLoading } = useQuery(["sellClient", dataClient?.IdUnique], () =>
    Api.get("/products/GetProductConsumed/" + dataClient?.IdUnique)
  );

  if ((data?.payload!=undefined) && (Array.isArray(data?.payload)) && (data?.payload.length > 0)) {
    var reukt = data?.payload?.reduce((x, y) => x?.total + y?.total,undefined)
  }
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if(data){
      let totalf = 0
      data.payload.forEach(element => {
         totalf = element.total + totalf
      });
      setTotal(totalf)
    }
  }, [data])
  return (
    <>
      <Modal 
          className='ModalTitle'
           //style={{colorText: 'rgba(100, 100, 30, 0.88)'}}
          // className="ant-modal" 
          title={<h1 style={{ color: 'white' }}>Consumo por cliente</h1>}
          visible={visible} 
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true}}
          onCancel={() => setVisible(!visible)}
          
       >
        
        <div >
          <Table className="tabla"> 
            <Thead>
              <Tr>
                <Th className="text-left">Descripción</Th>
                <Th className="text-right">Precio</Th>
                <Th className="text-center">Cantidad</Th>
                <Th className="text-right">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(data?.payload) && <>
                {data?.payload.map(i =>
                  <Tr>
                    <Td>
                      <div className="item text-left">{i?.NameProduct}</div>
                    </Td>
                    <Td>
                      <div className="Total">{formatter.format(i?.UnitValue)}</div>
                    </Td>
                    <Td>
                      <div className="item text-center">{i?.Amount}</div>
                    </Td>
                    <Td>
                      <div className="Total">{formatter.format(i?.total)}</div>
                    </Td>
                  </Tr>
                )}
              </>}
              <Tr>
                <Th className="tTotal">Valor Total:</Th>
                <Th></Th>
                <Th></Th>
                <Th><div className="VTotal">
                {formatter.format(total)}
                </div></Th>
              </Tr>
            </Tbody>
          </Table>
          {/*     <div className="flex justify-between w-full px-2 py-4">
            <h2 className="font-semibold">Total:</h2>
            <span >{Array.isArray(data?.payload) && data?.payload.reduce((a, b) => a?.total + b?.total, 0)}</span>
          </div> */}
        </div>
      </Modal>
      <Loading visible={isLoading} />

    </>
  )
}
