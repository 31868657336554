import React from "react";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { local } from "../../../services/Locals/LocalActions";
import Api from "../../../common/Api/Api";
import { Loading } from "../../Loading/Loading";
import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import Swal from "sweetalert2";

export const RequestField = ({ visible, setVisible, id, param }) => {
  const userData = jwt_decode(Token.getToken());

  // petición get para obtener los tipos de deportes

  const { data, isLoading } = useQuery("fieldTypes", () =>
    Api.get("/field/types")
  );
  const { data: fields, isLoading: fieldsLoading } = useQuery("fields", () => Api.get("/field/getfield/" + param));
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();


  const onSubmit = async (e) => {
    let validate = true
   fields.payload.map((item)=> {
    if(item.NameField.trim() == e.NameField.trim()){
      validate = false
    }
   })
   if(validate){
     dispatch(local.postField({
       ...e,
       NameField: e.NameField.trim(),
       idLocal: id,
       idCompany: userData?.data.IdCompany
     }));
   }else{
    Swal.fire({
      icon: "error",
      title: "Este nombre de cancha ya existe",
      text: "Intente agregar una cancha con otro nombre"
    })
   }
  };

  return (
    <Modal
      title={<h1 style={{ color: 'white' }}>Crear nueva cancha</h1>}
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="request-local">
        <div className="input-component3 flex flex-col">
          <p>Nombre de la cancha</p>
          <input type="text" required  {...register("NameField", { required: true })} />
        </div>
        <div className="input-component3 flex flex-col">
          <p>Precio en el día</p>
          <input type="number" required pattern="[0-9]+" {...register("PriceDay", { required: true })} />
        </div>
        <div className="input-component3 flex flex-col">
          <p>Precio en la noche</p>
          <input type="number" required pattern="[0-9]+" {...register("PriceNight", { required: true })} />
        </div>
        <div className="input-component3 w-11/12 flex flex-col">
          <p>Deporte</p>
          <select
            name="idFieldType"
            {...register("idFieldType", { required: true })}
          >
            {data?.payload?.map((i) => (
              <option value={i.IdFieldType}>{i.NameFieldType}</option>
            ))}
          </select>
        </div>
        <input type="submit" className="submit" value="Enviar" />
      </form>
      <Loading visible={isLoading} />
    </Modal>
  );
};
