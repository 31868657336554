import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const modalSucces = ({ message, url, reload, preventReload, title }) =>
  MySwal.fire({
    icon: "success",
    heightAuto: "false",
    type: "success",
    customClass: 'swal-height',
    title: message,
  }).then((result) => {
    /*      MySwal.fire(<p>{message}</p>)
     */
    if (!preventReload) {
      reload === true ? window.location.reload() : window.location.href = url;
    }
  });
