import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { modalError } from "../../SweetAlert/Error";
import { modalSucces } from "../../SweetAlert/Success";
import File from "../../Inputs/Files.jsx";
import Api from "../../../common/Api/Api";
import { Loading } from "../../Loading/Loading";
import { UploadFirebase } from "../../firebase/PromiseUpload";
import InputHour from "../../Inputs/InputHour/InputHour.jsx";
import Swal from "sweetalert2";
import Map from "../../Map/Map.jsx";

export const EditLocal = ({ visible, onClose, local }) => {
  const [value, setvalue] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [error, setError] = useState(false);
  //Values
  const [nameLocal, setNameLocal] = useState("");
  const [number, setNumber] = useState("");
  const [direccion, setDireccion] = useState("");
  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [view, setView] = useState(false);
  const [promoRedem, setPromoRedem] =useState("");

  useEffect(() => {
    setNumber(local?.TelefonoLocal);
    setNameLocal(local?.NameLocal);
    setDireccion(local?.LocalAddress);
    setPromoRedem(local?.promoRedem);
    setPosition({ lat: Number(local?.Latitude), lng: Number(local?.Longitude) });
  }, [local]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm();

  // Activa las peticion de tipo post para actualizar el local  controlando la respuesta de la misma

  useEffect(() => {
    if (value !== "") {
      setError(true);
    }
  }, [value]);

  const mutation = useMutation(
    (data) => {
      return Api.post("/local/UpdateLocal", data);
    },
    {
      onSuccess: (data) => {
    
        if (data?.ok === true) {
          modalSucces({
            message: "El local se ha editado de manera correcta",
            reload: true,
          });
        } else {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : "Revisa tus datos, por favor",
          });
        }
      },
      onError: () => {
        modalError({ message: "Parece que tenemos problemas" });
      },
    }
  );

  // Valida los datos del formulario,sube los archivos a firebase,espera la promesa y activa la peticion post

  const onSubmit = (e) => {
    if (openTime.substring(0, 2) < closeTime.substring(0, 2)) {
      if (value !== "") {
        return Promise.all([
          UploadFirebase({ value: value }).then(async (res) => {
            mutation.mutate({
              idLocal: local?.IdLocal,
              namelocal: nameLocal,
              address: direccion,
              longitud: position.lng,
              latitud: position.lat,
              city: local?.city,
              department: local?.department,
              country: local?.country,
              openTime: openTime || local?.openTime,
              closeTime: closeTime || local?.closeTime,
              TelefonoLocal: number,
              promoRedem: promoRedem,
              logo: res,
            });
          }),
        ]);
      } else {
        mutation.mutate({
          idLocal: local?.IdLocal,
          namelocal: nameLocal,
          address: direccion,
          longitud: position.lng,
          latitud: position.lat,
          city: local?.city,
          department: local?.department,
          country: local?.country,
          openTime: openTime || local?.openTime,
          closeTime: closeTime || local?.closeTime,
          TelefonoLocal: number,
          promoRedem: promoRedem,
          logo: local.Logo,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "La hora de apertura no puede ser menor a la hora de cierre",
      });
    }
  };

  return (
    <Modal
      className="z-1"
      title={<h1 style={{ color: 'white' }}>Editar local</h1>}
      open={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={onClose}
      destroyOnClose
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mx-4 justify-center items-center w-full request-local "
      >
        <div className="request-local__content w-full flex gap-10 ">
          <div className="w-1/2 in">
            <div className="input-component3 flex flex-col">
              <p>Nombre local</p>
              <input
                type="text"
                value={nameLocal}
                onInput={(e) => setNameLocal(e.target.value)}
                required
              />
            </div>
            <div className="ubiContainer">    
            <div className="ubiBtn flex">
              <label className="ubic p-1" onClick={() => setView(true)}>Elegir ubicación</label>
                <Map position={position} setPosition={setPosition} view={view} setView={setView} />
            </div>
            <div className="flex ubications">
              <div className="ubiIn flex ">
                <p>Latitud:</p>
                <input type="number " readOnly value={position.lat} required />
              </div>
              <div className="ubiIn flex">
                <p>Longitud:</p>
                <input type="number" readOnly value={position.lng} required />
              </div>
            </div>
            </div>
            
            <div className="w-11/12">
              <p className="font-bold">Logo</p>
              <File
                typeData={["image/png", "image/jpg", "image/jpeg"]}
                type="logo"
                setvalue={setvalue}
                value={value}
              />
              {error !== true && (
                <span className="text-base text-red-400">
                  El logo debe estar en formato png o jpg y tener un tamaño de
                  640x360
                </span>
              )}
            </div>
          </div>
          <div className="w-1/2 in">
            <div className="input-component3 flex flex-col">
              <p>Dirección</p>
              <input
                type="text"
                value={direccion}
                onInput={(e) => setDireccion(e.target.value)}
                required
              />
            </div>
            <div className="input-component3 flex flex-col">
              <p>Promoción Redimir Cancha</p>
              <input
                type="number"
                value={promoRedem}
                onInput={(e) => setPromoRedem(e.target.value)}
                required
              />
            </div>
            <div className="input-component3 flex flex-col">
              <p>Número de télefono</p>
              <input
                type="number"
                value={number}
                onInput={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <div className="w-11/12 pb-6">
              <p className="text-sm/[14] font-medium text-[#322b5f]">
                Hora de apertura
              </p>
              <div className="">
                <InputHour defaultValue={local?.openTime} set={setOpenTime} />
              </div>
            </div>
            <div className="w-11/12 pb-4">
              <p className="text-sm/[14] font-medium text-[#322b5f]">
                Hora de cierre
              </p>
              <div className="">
                <InputHour defaultValue={local?.closeTime} set={setCloseTime} />
              </div>
            </div>
          </div>
        </div>
        <input type="submit" className="btn-close p-2" value="Aceptar" />
      </form>
      <Loading visible={mutation.isLoading} />
    </Modal>
  );
};
